import { cn } from "@lib/cn"
import React, { FC, InputHTMLAttributes } from "react"
import { InputStatusProp } from "./types"

export type RadioProps = InputHTMLAttributes<HTMLInputElement> &
  InputStatusProp & {
    label: React.ReactNode
    id: string
  }

export const Radio: FC<RadioProps> = ({ label, id, status, ...rest }) => {
  const itemId =
    id ||
    rest.name ||
    `id-${[...Array(5)]
      .map((i) => (~~(Math.random() * 36)).toString(36))
      .join("")}`

  return (
    <button
      className={cn(
        "group relative flex items-center text-grey outline-0 transition-colors duration-150",
        "enabled:hover:text-white [&_>_*]:cursor-[inherit]",
        status === "disabled" && "!cursor-not-allowed !text-shade-600",
      )}
      data-disabled={status === "disabled" ? "" : undefined}
    >
      <input
        className={cn(
          "peer relative h-5 w-5 rounded-2xl opacity-0 disabled:cursor-not-allowed disabled:!text-shade-600",
        )}
        data-error={status === "error" ? "" : undefined}
        disabled={status === "disabled"}
        type="radio"
        id={itemId}
        {...rest}
      />
      <span
        className={cn(
          "pointer-events-none absolute left-0 flex h-5 w-5 items-center justify-center rounded-2xl border border-solid border-[currentColor]",
          "after:block after:h-[0.625rem] after:w-[0.625rem] after:scale-75 after:rounded-[0.3125rem] after:bg-white after:opacity-0 after:transition-opacity after:duration-150",
          "group-focus-visible:shadow-[0_0_0_3px] group-focus-visible:!shadow-shade-500/50",
          "peer-checked:after:scale-100 peer-checked:after:opacity-100",
          status === "error" && "border-danger",
        )}
        data-indicator
      />
      <label
        className={cn(
          "ml-3 block text-left text-sm",
          status !== "disabled" && "peer-checked:text-white",
        )}
        htmlFor={id}
        data-error={status === "error" ? "" : undefined}
      >
        {label}
      </label>
    </button>
  )
}

export default Radio
