import { cn } from "@lib/cn"

export const getBaseInputClass = (isError?: boolean) =>
  cn(
    "block w-full border border-solid border-shade-600 text-white bg-transparent transition-[border] duration-[0.25s] rounded-md font-medium text-base appearance-none lg:text-sm",
    "placeholder:opacity-0 hover:border-shade-400",
    isError && "border-danger",
    "focus-visible:outline-0 focus-visible:shadow-[0_0_0_1px] focus-visible:shadow-shade-500/50",
    "focus:border-shade-400 focus:shadow-[0_0_0_2px] focus:shadow-shade-600",
    "disabled:cursor-not-allowed disabled:pointer-events-none disabled:bg-disabled disabled:hover:border-transparent",
  )

export const getInputClass = ({ withSuffix }: { withSuffix?: boolean }) =>
  cn(
    "h-[3.25rem] px-4 pb-4 pt-8 leading-[3.25rem]",
    "webkit-outer-spin-button:m-0 webkit-outer-spin-button:appearance-none webkit-inner-spin-button:m-0 webkit-inner-spin-button:appearance-none",
    "placeholder:opacity-0 disabled:text-shade-500",
    withSuffix && "pt-4",
  )

export const getBaseInputLabelClass = ({
  isFilled,
  isError,
}: {
  isFilled?: boolean
  isError?: boolean
}) =>
  cn(
    "absolute left-4 top-1/2 origin-[left_center] -translate-y-1/2 text-base font-medium text-shade-500 transition-all duration-200 ease-in-out placeholder:opacity-0 lg:text-sm",
    isFilled &&
      "translate-x-[1px] translate-y-[-110%] scale-[0.8] text-shade-500",
    isError && "text-danger",
  )
