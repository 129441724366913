import cookies from "js-cookie"
import { useState } from "react"

export function useNumericStopperTooltip() {
  const [open, setIsOpen] = useState(false)
  let shouldBeDisplayed = true

  if (open && shouldBeDisplayed) {
    const hasShownTooltip = cookies.get(
      "has-displayed-numeric-stopper-tooltip-nth-times",
    )
    const hasShownTooltipNthTimes = hasShownTooltip
      ? Number(hasShownTooltip)
      : 0

    // only show the tooltip the first two times the user activates the numeric stopper
    if (hasShownTooltipNthTimes >= 2) {
      shouldBeDisplayed = false
    } else {
      cookies.set(
        "has-displayed-numeric-stopper-tooltip-nth-times",
        String(hasShownTooltipNthTimes + 1),
        {
          expires: 1,
        },
      )
    }
  }

  return {
    open,
    setIsOpen,
    shouldBeDisplayed,
  }
}
