import { cn } from "@lib/cn"
import * as RadixCheckbox from "@radix-ui/react-checkbox"
import React, { FC, InputHTMLAttributes } from "react"
import { IconCheck } from "../icons/IconCheck"
import { InputStatusProp } from "./types"

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> &
  InputStatusProp & {
    label: React.ReactNode
    id: string
    onCheckedChange?: RadixCheckbox.CheckboxProps["onCheckedChange"]
    hideLabel?: boolean
    className?: string
    variant?: "regular" | "gradient-fill"
  }

export const Checkbox: FC<CheckboxProps> = ({
  label,
  id,
  checked,
  name,
  onCheckedChange,
  hideLabel,
  status = "idle",
  className,
  variant = "regular",
}) => {
  return (
    <RadixCheckbox.Root
      checked={checked}
      id={id}
      name={name}
      onCheckedChange={onCheckedChange}
      className={cn(
        "relative flex items-center text-grey outline-0 transition-colors duration-150 [&_>_*]:cursor-[inherit]",
        // focus-visible:[&_span] will be rendered as span:focus-visible, not the way we want, so need to use [&:focus-visible_span]
        "[&:focus-visible_span]:shadow-[0_0_0_3px] [&:focus-visible_span]:shadow-shade-500/50",
        "disabled:!cursor-not-allowed disabled:!text-shade-600",
        "enabled:hover:text-white",
        checked &&
          "[&:not(:disabled)_label]:text-white [&_span_svg]:opacity-100",
        className,
      )}
      disabled={status === "disabled"}
    >
      <span
        className={cn(
          "relative flex h-5 w-5 items-center justify-center overflow-hidden rounded border border-solid border-[currentColor] transition-[border] duration-150",
          "after:absolute after:h-full after:w-full after:bg-default after:opacity-0 after:transition-opacity after:duration-[0.25s]",
          "[&_svg]:relative [&_svg]:z-[1] [&_svg]:text-white [&_svg]:opacity-0 [&_svg]:transition-opacity [&_svg]:duration-150",
          checked &&
            variant === "gradient-fill" &&
            "border-none after:opacity-100",
          status === "error" && "border-danger",
        )}
        // Keep data-* attributes for backward compatibility with  other usages
        // TODO: remove data-* attributes when everything is migrated to tailwind
        data-variant={variant}
        data-error={status === "error" ? "" : undefined}
        data-indicator
        data-checked={checked ? "" : undefined}
      >
        <IconCheck size={14} />
      </span>
      <label
        className={cn(
          "text-regular ml-3 block text-left lg:text-sm",
          hideLabel && "hidden",
        )}
        data-hide-label={hideLabel ? "" : undefined}
        htmlFor={id}
        data-error={status === "error" ? "" : undefined}
        data-disabled={status === "disabled" ? "" : undefined}
      >
        {label}
      </label>
    </RadixCheckbox.Root>
  )
}
