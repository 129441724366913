import { IconButton } from "@components/ui/button/IconButton"
import { cn } from "@lib/cn"
import { Root, Trigger } from "@radix-ui/react-tooltip"
import { FC, useRef } from "react"
import { NumericStopperType } from "../Field"
import { getBaseInputClass } from "../styles"
import { useNumericStopper } from "./hooks/use-numeric-stopper"
import { useNumericStopperTooltip } from "./hooks/use-numeric-stopper-tooltip"
import { NumericStopperTooltip } from "./tooltip"

export const NumericStopper: FC<NumericStopperType> = ({
  min,
  max,
  step,
  name,
  value,
  status,
  className,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const id = rest.id || name
  const { open, setIsOpen, shouldBeDisplayed } = useNumericStopperTooltip()
  const { handleDecrement, handleIncrement, canDecrement, canIncrement } =
    useNumericStopper(ref, min, max)

  return (
    <div className="relative flex items-center justify-between gap-2">
      <IconButton
        icon="minus"
        size="small"
        variant="secondary"
        label={`Decrement ${name} value`}
        onClick={handleDecrement}
        disabled={!canDecrement || status === "disabled"}
        type="button"
      />
      <Root open={open} onOpenChange={setIsOpen}>
        <Trigger asChild>
          <input
            className={cn(
              getBaseInputClass(),
              "appearance-[textfield] w-[5.5ch] cursor-default border-transparent p-1 text-center caret-transparent",
              "selection:bg-transparent webkit-outer-spin-button:m-0 webkit-outer-spin-button:appearance-none webkit-inner-spin-button:m-0 webkit-inner-spin-button:appearance-none",
              "hover:border-transparent hover:shadow-[0_0_0_1px] hover:shadow-shade-500/50 hover:outline-0",
              "focus:border-transparent focus:shadow-[0_0_0_2px] focus:shadow-shade-600",
              "focus-within:border-transparent focus-within:shadow-[0_0_0_2px] focus-within:shadow-shade-600",
              "focus-visible:border-transparent focus-visible:shadow-[0_0_0_2px] focus-visible:shadow-shade-600",
              "placeholder:opacity-0 disabled:text-shade-500",
              className,
            )}
            data-status={status}
            name={name}
            type="number"
            id={id}
            min={min}
            max={max}
            ref={ref}
            step={step}
            value={(value as number).toFixed(2)}
            disabled={status === "disabled"}
            onClick={() => setIsOpen(true)}
            {...rest}
          />
        </Trigger>
        {shouldBeDisplayed && <NumericStopperTooltip />}
      </Root>
      <IconButton
        icon="plus"
        size="small"
        variant="secondary"
        label={`Increment ${name} value`}
        onClick={handleIncrement}
        disabled={!canIncrement || status === "disabled"}
        type="button"
      />
    </div>
  )
}
