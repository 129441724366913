import { FC } from "react"
import { IconButton } from "../button/IconButton"
import { Input, InputProps } from "./Input"

export type InputAndSubmitProps = Omit<InputProps, "suffix"> & {
  isSubmitting?: boolean
}

export const InputAndSubmit: FC<InputAndSubmitProps> = ({
  isSubmitting = false,
  ...rest
}) => {
  return (
    <div className="relative">
      <Input {...rest} className="!pr-12" />
      <div className="absolute right-2 top-1/2 -translate-y-1/2">
        <IconButton
          status={isSubmitting ? "loading" : "idle"}
          variant="secondary"
          size="extra-small"
          icon="chevron-right"
          label="submit"
          type="submit"
        />
      </div>
    </div>
  )
}
