import { cn } from "@lib/cn"
import * as RadixSwitch from "@radix-ui/react-switch"
import React, { ButtonHTMLAttributes, FC } from "react"
import { InputStatusProp } from "./types"

export type SwitchProps = InputStatusProp & {
  onChange?: (value: boolean) => void
  toggled?: boolean
  label?: React.ReactNode
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onChange">

export const Switch: FC<SwitchProps> = ({
  className,
  status,
  toggled,
  onChange,
  label,
}) => {
  return (
    <div className={"flex items-center gap-3"}>
      <RadixSwitch.Root
        className={cn(
          "group relative h-[12px] w-[26px] appearance-none rounded-3xl bg-shade-600 outline-0 transition duration-200",
          "focus-visible:shadow-outline focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-disabled",
          toggled && "bg-default",
          className,
        )}
        onCheckedChange={onChange}
        disabled={status === "disabled"}
        checked={toggled}
      >
        <RadixSwitch.Thumb
          className={cn(
            "absolute left-0 top-0 flex h-[12px] w-[12px] items-center justify-center rounded-full bg-white",
            "text-shade-500 transition duration-200 ease-in-out",
            toggled && "translate-x-[14px] text-success",
            status === "disabled" && "bg-shade-400",
          )}
        />
      </RadixSwitch.Root>
      {label && (
        <label
          className={cn(
            "block text-left text-sm",
            status !== "disabled" && "peer-checked:text-white",
          )}
        >
          {label}
        </label>
      )}
    </div>
  )
}
