import { Text } from "@components/ui/typograhpy/Text"
import { cn } from "@lib/cn"
import { Arrow, Content } from "@radix-ui/react-tooltip"
import { FC } from "react"
import { KeyboardArrowIcon } from "./KeyboardArrow"

export const NumericStopperTooltip: FC = () => {
  return (
    <>
      <Content
        align="center"
        side="top"
        sideOffset={6}
        className={cn(
          "relative z-[5] flex max-w-[calc(100vw-2rem)] animate-slide-in-tooltip",
          "items-center gap-1 rounded-md border-none bg-shade-600 p-4 outline-none",
          "sm:max-w-[50vw] lg:max-w-[288px]",
        )}
        collisionPadding={16}
      >
        <Arrow className="fill-shade-600" />
        <div className="flex items-center">
          <div className="mr-1 flex h-5 w-5 shrink-0 items-center justify-center rounded-[5px] bg-border-opacity">
            <KeyboardArrowIcon />
          </div>
          <div
            className="mr-1 flex h-5 w-5 shrink-0 rotate-180 items-center justify-center rounded-[5px] bg-border-opacity"
            data-rotate-icon
          >
            <KeyboardArrowIcon />
          </div>
        </div>
        <Text variant="small" className="text-white">
          Adjust value
        </Text>
      </Content>
    </>
  )
}
