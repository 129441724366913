import { Box } from "@components/Box"
import { cn } from "@lib/cn"
import React, { FC, ReactNode } from "react"
import { Span } from "../typograhpy/Span"

export type FieldControlProps = {
  errorMsg?: string
  className?: string
  info?: string
  children?: ReactNode
}

export const FieldControl: FC<FieldControlProps> = ({
  errorMsg,
  info,
  children,
  className,
}) => {
  return (
    <Box className={cn("mb-2", className)}>
      {children}
      {typeof errorMsg === "string" ? (
        <Span
          variant="input-label-active"
          className="mt-[0.375rem] block text-danger"
        >
          {errorMsg}
        </Span>
      ) : (
        typeof info === "string" && (
          <Span
            variant="input-label-active"
            className="mt-[0.375rem] block text-border-grey"
          >
            {info}
          </Span>
        )
      )}
    </Box>
  )
}
