import { cn } from "@lib/cn"
import React, { FC, SelectHTMLAttributes } from "react"
import { IconChevronDown } from "../icons/IconChevronDown"
import { getBaseInputClass, getBaseInputLabelClass } from "./styles"
import { InputStatus } from "./types"

export type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string
  status?: InputStatus
}

export const Select: FC<SelectProps> = ({
  status = "idle",
  label,
  className,
  ...rest
}) => {
  return (
    <div className="relative">
      <select
        disabled={status === "disabled"}
        data-status={status}
        className={cn(
          getBaseInputClass(status === "error"),
          "h-[3.25rem] break-words px-4 pr-8 leading-[3.25rem]",
          "select-option:h-12 select-option:bg-shade-600 select-option:text-white",
          className,
        )}
        {...rest}
      />

      <label
        className={getBaseInputLabelClass({
          isFilled: true,
          isError: status === "error",
        })}
        htmlFor={rest.id || rest.name}
      >
        {label}
      </label>
      <div className="pointer-events-none absolute bottom-px right-px top-px flex items-center pr-4">
        <IconChevronDown size={16} />
      </div>
    </div>
  )
}
