import { cn } from "@lib/cn"
import React, { FC } from "react"
import { Text } from "../../typograhpy/Text"
import { RangeType } from "../Field"
import { getBaseInputClass } from "../styles"
import { useRange } from "./hooks/use-range"
import { RangeMarkers } from "./Markers"

export type RangeProps = RangeType & {
  showChangeIndicator?: boolean
}

export const Range: FC<RangeProps> = ({
  onChange,
  onFocus,
  onBlur,
  status = "idle",
  className,
  label,
  value,
  name,
  min,
  max,
  step,
  hueVariant,
  showChangeIndicator,
  ...rest
}) => {
  const id = rest.id || name
  const {
    isValueNegative,
    fillPercentage,
    fillPositionLeft,
    fillPositionRight,
  } = useRange(value as number, min, max)

  const disabled = status === "disabled"

  return (
    <div
      className={cn(
        "group relative rounded-md transition-all duration-150 ease-linear",
        "focus-within:ring-shade-600 focus-within:ring-2  focus:ring-shade-600 focus:ring-2 focus-visible:ring-shade-600 focus-visible:ring-2",
        disabled
          ? "cursor-not-allowed opacity-80"
          : "cursor-ew-resize hover:ring-shade-600 hover:ring-2",
      )}
    >
      {showChangeIndicator && (
        <span className="absolute -left-[10px] top-1/2 block h-1 w-1 -translate-y-1/2 rounded-full bg-danger" />
      )}
      <input
        className={cn(
          getBaseInputClass(),
          "appearance-[textfield] h-11 cursor-ew-resize border-transparent bg-opacity px-4 leading-[2.75rem]",
          "webkit-outer-spin-button:m-0 webkit-outer-spin-button:appearance-none webkit-inner-spin-button:m-0 webkit-inner-spin-button:appearance-none",
          "webkit-slider-thumb:opacity-0 moz-range-thumb:border-none moz-range-thumb:bg-transparent",
          "focus-within:border-transparent focus-within:shadow-none focus-within:outline-0 focus:border-transparent focus:shadow-none focus:outline-0 focus-visible:border-transparent focus-visible:shadow-none focus-visible:outline-0",
          !disabled &&
            "hover:border-transparent hover:shadow-none hover:outline-0",
          "placeholder:opacity-0 disabled:text-shade-500",
          className,
        )}
        onChange={handleChange}
        data-status={status}
        name={name}
        type="range"
        id={id}
        value={value}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        {...rest}
      />
      <label
        className={cn(
          "pointer-events-none absolute left-4 top-1/2 z-[1] origin-[left_center] -translate-y-1/2 text-base font-medium text-white transition-all duration-200 ease-in-out",
          "placeholder:opacity-0 lg:text-sm",
          status === "error" && "text-danger",
        )}
        htmlFor={id}
      >
        <Text variant="small" className="font-semibold text-inherit" as="span">
          {label}
        </Text>
      </label>
      <div
        className={cn(
          "pointer-events-none absolute left-0 top-0 h-full w-full overflow-hidden rounded-md",
          hueVariant && "opacity-80",
          hueVariant === "vegetation" &&
            "bg-[linear-gradient(90deg,#5b964f_0%,#4f9674_100%)]",
          hueVariant === "skin" &&
            "bg-[linear-gradient(90deg,#634136_0%,#8c6e45_100%)]",
          hueVariant === "sky" &&
            "bg-[linear-gradient(90deg,#487a93_0%,#466095_100%)]",
        )}
        data-hue-variant={hueVariant ? hueVariant : undefined}
      >
        <span
          className={cn(
            "pointer-events-none absolute h-full border-y-0 border-l-0 border-r-2 border-transparent border-r-transparent bg-shade-600",
            !disabled &&
              "group-focus-within:border-white group-hover:border-white group-hover:bg-shade-500 group-focus:border-white group-focus-visible:border-white",
            isValueNegative && "border-l-2 border-r-0",
            hueVariant && "mix-blend-color-dodge",
          )}
          data-fill
          data-fill-value-negative={isValueNegative ? "" : undefined}
          data-hue-variant={hueVariant ? "" : undefined}
          style={{
            width: `${fillPercentage}%`,
            left: fillPositionLeft,
            right: fillPositionRight,
          }}
        />
      </div>
      <input
        type="number"
        min={min}
        max={max}
        name={name}
        step={step}
        value={getFormattedValue()}
        disabled={disabled}
        onChange={handleChange}
        className={cn(
          getBaseInputClass(),
          "absolute right-3 top-1/2 w-[5ch] -translate-y-1/2 border-transparent py-1 text-center",
          "appearance-[textfield] webkit-outer-spin-button:m-0 webkit-outer-spin-button:appearance-none webkit-inner-spin-button:m-0 webkit-inner-spin-button:appearance-none",
          "focus-visible:border focus-visible:border-solid focus-visible:border-white focus-visible:bg-opacity focus-visible:shadow-none",
          hueVariant === "vegetation" &&
            "focus-visible:bg-[rgba(79,150,116,0.8)]",
          hueVariant === "skin" && "focus-visible:bg-[rgba(140,110,69,0.8)]",
          hueVariant === "sky" && "focus-visible:bg-[rgba(70,96,149,0.8)]",
        )}
        data-hue-variant={hueVariant ? hueVariant : undefined}
        {...rest}
      />
      {!disabled && (
        <RangeMarkers
          className={cn(
            "group-hover:translate-y-0 group-hover:opacity-40",
            "group-focus-within:translate-y-0 group-focus-within:opacity-40",
            "group-focus-visible:translate-y-0 group-focus-visible:opacity-40",
          )}
        />
      )}
    </div>
  )

  function getFormattedValue() {
    if (typeof value !== "number") {
      return value
    }

    return value.toFixed(2)
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value)

    if (value <= max && value >= min) {
      onChange?.(e)
    }
  }
}
