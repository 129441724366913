import { RefObject, useEffect } from "react"

export function useNumericStopper(
  inputRef: RefObject<HTMLInputElement>,
  min: number,
  max: number,
) {
  useEffect(() => {
    const currentRef = inputRef.current
    if (!currentRef) return

    function handleKeyDown(event: KeyboardEvent) {
      // if key is numeric, prevent the default behavior
      // this disables manual numeric input, while maintaining the ability to use the arrow keys
      if (event.key.match(/[0-9]/)) {
        event.preventDefault()
      }
    }

    currentRef?.addEventListener("keydown", handleKeyDown)

    return () => {
      currentRef?.removeEventListener("keydown", handleKeyDown)
    }
  }, [inputRef])

  function handleIncrement() {
    if (!inputRef.current) return
    inputRef.current.stepUp()
    triggerChangeEvent()
  }

  function handleDecrement() {
    if (!inputRef.current) return
    inputRef.current.stepDown()
    triggerChangeEvent()
  }

  function triggerChangeEvent() {
    // Apparently, the input element doesn't trigger a change event when the value is changed using `stepUp` or `stepDown`
    // https://stackoverflow.com/questions/16250464/trigger-change-event-when-the-input-value-changed-programmatically
    if (!inputRef.current) return
    inputRef.current.dispatchEvent(new Event("change", { bubbles: true }))
  }

  return {
    handleIncrement,
    handleDecrement,
    canDecrement: inputRef.current ? +inputRef.current.value > min : true,
    canIncrement: inputRef.current ? +inputRef.current.value < max : true,
  }
}
