import React, { FC } from "react"

export const KeyboardArrowIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      fill="none"
      viewBox="0 0 10 12"
    >
      <path
        stroke="#A8AFBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M5 1l4 5.5H6.5V11h-3V6.5H1L5 1z"
      ></path>
    </svg>
  )
}
