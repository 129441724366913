import { CSSProperties } from "react"

export function useRange(value: number, min: number, max: number) {
  const isValueNegative = value ? value < 0 : false
  const valueCanBeNegative = min < 0

  let fillPercentage = ((Math.abs(value) - min) / (max - min)) * 100
  let fillPositionLeft: CSSProperties["left"] = 0
  let fillPositionRight: CSSProperties["right"] = "auto"

  // If the value can be negative, the fill bar will start out in the middle,
  // either going left or right depending on the value.
  // therefore we need to calculate the width of the fill bar based on half the range.
  if (valueCanBeNegative) {
    const halfRange = Math.abs(min) + Math.abs(max)
    fillPositionLeft = isValueNegative ? "unset" : "50%"
    fillPositionRight = isValueNegative ? "50%" : "unset"
    fillPercentage = (Math.abs(value) / halfRange) * 100
  }

  return {
    fillPositionLeft,
    fillPositionRight,
    fillPercentage,
    isValueNegative,
  }
}
