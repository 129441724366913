import { cn } from "@lib/cn"

const markerPoints = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40,
  42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78,
  80, 82, 84, 86, 88, 90, 92, 94, 96, 98,
]

type Props = {
  className?: string
}
export const RangeMarkers = ({ className }: Props) => {
  return (
    <div>
      {markerPoints.map((point) => (
        <span
          data-markers
          className={cn(
            "absolute top-0 block h-1.5 w-px translate-y-[-10%] bg-white opacity-0 transition-all duration-100 ease-in-out",
            point % 10 === 0 && "h-3",
            className,
          )}
          key={point}
          data-decimal={point % 10 === 0 ? "" : undefined}
          style={{
            left: `${point}%`,
          }}
        />
      ))}
    </div>
  )
}
