import { cn } from "@lib/cn"
import { useField, useFormikContext } from "formik"
import { FC } from "react"
import { Checkbox, CheckboxProps } from "./Checkbox"
import { FieldControl, FieldControlProps } from "./FieldControl"
import { Input, InputProps } from "./Input"
import { InputAndSubmit } from "./InputAndSubmit"
import { NumericStopper } from "./numeric-stopper"
import Radio, { RadioProps } from "./Radio"
import { Range } from "./range"
import { Select, SelectProps } from "./Select"
import { Switch, SwitchProps } from "./Switch"
import { TextArea, TextAreaProps } from "./TextArea"
import { InputStatus } from "./types"
import { validateField, ValidationOptions } from "./validate"

type SelectType = SelectProps & {
  field: "select"
}

type InputType = InputProps & {
  field: "input" | "textarea" | "input-and-submit"
}

export type RangeType = InputProps & {
  field: "range"
  min: number
  max: number
  step?: number
  hueVariant?: "sky" | "skin" | "vegetation"
}

export type NumericStopperType = InputProps & {
  field: "numeric-stopper"
  min: number
  max: number
  step?: number
}

type CheckboxType = CheckboxProps & {
  field: "checkbox"
}

type RadioType = RadioProps & {
  field: "radio"
}

type SwitchType = SwitchProps & {
  field: "switch"
}

type FieldType =
  | InputType
  | SelectType
  | CheckboxType
  | RadioType
  | SwitchType
  | RangeType
  | NumericStopperType
type FieldProps = FieldControlProps &
  FieldType & {
    validation?: ValidationOptions
    fieldName?: string
    info?: string
  }

export const Field: FC<FieldProps> = ({
  field,
  validation,
  disabled,
  status,
  label,
  fieldName = "This field",
  className,
  info,
  ...rest
}) => {
  const [fieldProps, meta, { setValue }] = useField({
    name: rest.name as string,
    type: ["checkbox", "switch"].includes(field) ? "checkbox" : "text",
    validate: validation && validateField(validation, fieldName),
  })
  const { isSubmitting } = useFormikContext()
  const { onBlur, ...restFieldProps } = fieldProps

  let givenStatus: InputStatus = status || "idle"
  let errorMsg: undefined | string = undefined
  if (meta.touched && meta.error) {
    givenStatus = "error"
    errorMsg = meta.error
  }
  if (status === "disabled" || isSubmitting) {
    givenStatus = "disabled"
  }

  if (disabled && process.env.NODE_ENV === "development") {
    console.warn(
      `disabled prop passed, this is not supported. Please use status="disabled"`,
    )
  }

  let input: any = null
  switch (field) {
    case "input":
      input = (
        <Input
          label={label as string}
          status={givenStatus}
          {...(rest as Omit<InputProps, "label">)}
          {...restFieldProps}
        />
      )
      break

    case "input-and-submit":
      input = (
        <InputAndSubmit
          label={label as string}
          status={givenStatus}
          isSubmitting={isSubmitting}
          {...(rest as Omit<InputProps, "label" | "suffix">)}
          {...restFieldProps}
        />
      )
      break

    case "textarea":
      input = (
        <TextArea
          label={label as string}
          status={givenStatus}
          {...(rest as Omit<TextAreaProps, "label">)}
          {...restFieldProps}
        />
      )
      break

    case "select":
      input = (
        <Select
          status={givenStatus}
          {...(rest as SelectProps)}
          {...restFieldProps}
        />
      )
      break

    case "checkbox":
      input = (
        <Checkbox
          label={label as string}
          status={givenStatus}
          onCheckedChange={(bool) => setValue(bool)}
          {...(rest as Omit<CheckboxProps, "label">)}
          {...restFieldProps}
        />
      )
      break

    case "radio":
      return (
        <Radio
          label={label}
          {...(rest as Omit<RadioProps, "label">)}
          {...restFieldProps}
        />
      )

    case "switch":
      input = (
        <Switch
          status={givenStatus}
          toggled={fieldProps.value}
          {...(rest as SwitchProps)}
          {...fieldProps}
          onChange={(bool) => setValue(bool)}
        />
      )
      break

    case "range":
      input = (
        <Range
          label={label as string}
          status={givenStatus}
          {...(rest as Omit<RangeType, "label">)}
          {...restFieldProps}
        />
      )
      break

    case "numeric-stopper":
      input = (
        <NumericStopper
          label={label as string}
          status={givenStatus}
          {...(rest as Omit<NumericStopperType, "label">)}
          {...restFieldProps}
        />
      )
      break
  }

  return (
    input && (
      <FieldControl
        className={cn("mb-5", className)}
        info={info}
        errorMsg={errorMsg}
      >
        {input}
      </FieldControl>
    )
  )
}
